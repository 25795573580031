import React, { useState } from 'react';

import { Form } from '@unform/web';
import api from '../../services/api';

import { 
    Container, ContactWrapper, ContactWrapperText, 
    ContactForm, ContactFormText, ContactFormSection } from './styles';
import ContactImg from '../../images/Contact/contact.svg';
import { MdKeyboardBackspace } from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import InputMask from "react-input-mask";

function Contact() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [nameValidate, setNameValidate] = useState('');
  const [emailValidate, setEmailValidate] = useState('');
  const [phoneValidate, setPhoneValidate] = useState('');

  const insert = () => {
    if (name.trim() === '') {
      setNameValidate('O nome é obrigatório.')
    }

    if (email.trim() === '') {
      setEmailValidate('O e-mail é obrigatório.')
    }

    if (phone.trim() === '') {
      setPhoneValidate('O telefone é obrigatório.')
    }

    else {
      api.post('/leads', {name, email, phone})
      .then(response => {
        console.log(response.data)
        if (response.data.erro) {
          Error(response.data.erro)
        }
        else {
          toast.success('Informações enviadas com sucesso!');
          clear();
        }
      })
      .catch(e => console.log(e.message))
    }
  }

  const clear = () => {
    setName('');
    setEmail('');
    setPhone('');
}

  return(
    <Container>
        <ContactWrapper>
            <a href="/">
              <div className="icon_back">
                <MdKeyboardBackspace size="2em" color="#fff"/>
                <span>Voltar</span>
              </div>
            </a>
            <ContactWrapperText>
              <h1>Entre em Contato com a Equipe Remota</h1>
            </ContactWrapperText>
            <img src={ContactImg} alt="" width="650"/>
        </ContactWrapper>

        <ContactForm>
        <ToastContainer />
            <ContactFormText>
               <div className="title">
                  <h1>Contato!</h1>
                  <p>Preencha o formulário abaixo com suas informações.</p>
               </div>
            </ContactFormText>
            <ContactFormSection>
              <Form>
                <div className="input-section">
                  <span>Nome</span>
                  <input type="text" name="name" value={name} onChange={e => setName(e.target.value)} placeholder="Digite seu nome completo" />
                  {nameValidate !== '' && <div className="validation"><p>{nameValidate}</p></div>}
                </div>

               <div className="input-section">
                  <span>E-mail</span>
                  <input type="email" name="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Digite seu e-mail" />
                  {emailValidate !== '' && <div className="validation"><p>{emailValidate}</p></div>}
                </div>

                <div className="input-section">
                  <span>Telefone</span>
                  <InputMask 
                  mask="+55 (99)9-9999-9999" 
                  type="text" 
                  name="telefone" 
                  value={phone} 
                  onChange={e => setPhone(e.target.value)} 
                  placeholder="Digite seu telefone com DDD" 
                  />
                  {phoneValidate !== '' && <div className="validation"><p>{phoneValidate}</p></div>}
                </div>

                <button type="submit" onClick={insert}>Enviar</button>
              </Form>
            </ContactFormSection>
        </ContactForm>
    </Container>
  );
}

export default Contact;