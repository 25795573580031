import React from 'react';

import GlobalStyles from '../global/styles';
import SEO from "../components/seo";

import ContactComp from '../components/Contact';


function Contato() {
  return(
    <>
      <GlobalStyles />
      <ContactComp />
      <SEO title="Contato" />
    </>
  );
}

export default Contato;