import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    @media (max-width: 1136px) {
        flex-direction: column;
        height: auto;
    }
`;

export const ContactWrapper = styled.div`
    background-color: var(--color-secondary);
    min-height: 100vh;
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    box-shadow: 4px 4px 10px 4px rgba(0,0,0,0.2);

    > img {
        padding-top: 100px;
    }

    a {
        position: relative;
        right: 40%;
        bottom: 20px;
    }

    .icon_back {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        top: 30px;

        span {
            color: var(--color-white);
            padding-left: 5px;
            font-weight: 600;
        }
    }

    @media (max-width: 1136px) {
        min-height: 0;
        width: 100%;

        > img {
            padding-top: 70px;
        }
    }

    @media (max-width: 677px) {
        > img {
            width: 450px;
        }
    }

    @media (max-width: 550px) {
        > img {
            width: 400px;
        }
    }

    @media (max-width: 483px) {
        > img {
            width: 350px;
        }
    }

    @media (max-width: 360px) {
        .icon_back {
            left: 10px;
        }
    }

    @media (max-width: 320px) {
        > img {
            width: 300px;
        }

        .icon_back {
            left: 10px;
        }
    }
`;

export const ContactWrapperText = styled.div`
    text-transform: capitalize;
    padding-top: 40px;
    color: var(--color-white);
    letter-spacing: 2px;
    font-weight: 700;
    text-align: center;

    @media (max-width: 550px) {
        font-size: 15px;
    }
`;

export const ContactForm = styled.div`
    background-color: var(--color-white);
    min-height: 100vh;
    flex: 1;

    box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.2);

    .validation {
        padding: 5px 0 10px;
        font-size: 14px;
        color: #E14A58;
        font-weight: bold;
    }

    @media (max-width: 1136px) {
        min-height: 0;
        width: 100%;
    }
`;

export const ContactFormText = styled.div`
    padding: 60px 100px 40px 100px;

    h1 {
        color: var(--text-color-terciary);
        padding-bottom: 10px;
    }

    p {
        color: var(--text-color-quartenary);
        font-size: 17px;
    }

    @media (max-width: 677px) {
        padding: 60px 80px 40px 80px;
    }

    @media (max-width: 550px) {
        padding: 60px 40px 40px 40px;
    }
`;

export const ContactFormSection = styled.div`
    padding: 0 100px 0 100px;

    .input-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .input-section span {
        padding-bottom: 10px;
        color: var(--text-color-quartenary);
        font-weight: 600;
    }

    .input-section input, .input-section textarea {
        height: 55px;
        padding-left: 10px;
        border: 1px solid #C9E287;
        border-radius: 5px;
        resize: none;
        color: var(--text-color-quartenary);

        &:focus {
            border: 2px solid var(--color-secondary);
        }
    }

    .input-section textarea {
        height: 70px;
        padding-top: 10px;
    }
    
    button {
        width: 100%;
        height: 60px;
        margin-top: 20px;
        border: none;
        border-radius: 10px;
        background-color: var(--color-primary);
        color: var(--color-white);
        font-weight: 600;
        font-size: 17px;
        transition: 0.8s;

        &:hover {
            background-color: var(--color-secondary);
        }
    }

    @media (max-width: 1136px) {
        padding: 0 100px 60px 100px;
    }

    @media (max-width: 677px) {
        padding: 0 80px 60px 80px;
    }

    @media (max-width: 550px) {
        padding: 0 40px 65px 40px;
    }
`; 